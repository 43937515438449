<template>
  <div class="container sale__info border-top pt-3 px-0">
    <div class="px-2">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h1 class="h6 text-dark mb-0">
          Info Penjualan
        </h1>
        <feather-icon icon="ChevronUpIcon" size="24" />
      </div>

      <div class="d-flex justify-content-between flex-row">
        <div class="d-flex flex-column">
          <h6 class="text-dark text-darken-4 mb-1 size12">
            Penjualan Hari Ini
          </h6>
          <h5 class="text-dark mb-0 size14">
            Rp 12.302.000
          </h5>
        </div>
        <div class="d-flex flex-column">
          <feather-icon class="text-dark text-darken-6" icon="TrendingUpIcon" size="24" />
          <h6 class="text-dark text-darken-4 mb-1 size12">
            3.3%
          </h6>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-between flex-row">
        <div class="d-flex flex-column">
          <h6 class="text-dark text-darken-4 mb-1 size12">
            Penjualan Feb 2022
          </h6>
          <h5 class="text-dark mb-0 size14">
            Rp 34.302.000
          </h5>
        </div>
        <div class="d-flex flex-column">
          <feather-icon class="text-danger" icon="TrendingDownIcon" size="24" />
          <h6 class="text-dark text-darken-4 mb-1 size12">
            1.3%
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.container.sale__info {

}
</style>
