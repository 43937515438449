const userData = JSON.parse(localStorage.getItem('userData'));
const renewalStatus = userData?.merchant?.renewal_status;

const masterMenu = [
  {
    title: 'Master',
    icon: require('@/assets/images/icons/User.svg'),
    iconActive: require('@/assets/images/icons/User-Solid.svg'),
    children: [
      {
        title: 'Profil Toko',
        route: 'master-toko.index',
        permission: 'edit profil toko',
      },
      // {
      //   title: 'Billing',
      //   route: 'master-billing.index',
      //   permission: 'billing',
      // },
      {
        title: 'Billing',
        route: 'master-billing.index',
        permission: 'billing',
      },
      {
        title: 'Lokasi SKU',
        route: 'master-gudang.index',
        permission: 'list gudang',
        disabled: renewalStatus === 'free'
      },
      {
        title: 'Pengguna',
        route: 'master-users.index',
        permission: 'list user',
      },
      {
        title: 'Sales',
        route: 'master-sales.index',
        permission: 'list sales',
        disabled: renewalStatus === 'free'
      },
      {
        title: 'Cabang',
        route: 'master-cabang.index',
        permission: 'list cabang',
        disabled: renewalStatus === 'free'
      },
      {
        title: 'Daftar Rekening',
        route: 'master-rekening.index',
        permission: 'list rekening',
      },
    ],
  },
];

const filteredMasterMenu = masterMenu.map(menu => {
  if (menu.children) {
    return {
      ...menu,
      children: menu.children.filter(childMenu => !childMenu.disabled),
    };
  }
  return menu;
});

export default filteredMasterMenu;
